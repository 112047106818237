import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Title } from 'react-admin';

export default () => (
  <Card>
    <Title title="Labora - Painel Administrativo" />
    <CardHeader title="Labora Admin" />
  </Card>
);
