import {
	fetchUtils,
	GET_LIST,
	GET_ONE,
	GET_MANY,
	GET_MANY_REFERENCE,
	CREATE,
	UPDATE,
	UPDATE_MANY,
	DELETE,
	DELETE_MANY,
} from 'react-admin';

const querystring = require('querystring');

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
	const convertDataRequestToHTTP = (type, resource, params) => {
		let url = '';
		const options = {};
		switch (type) {
			case GET_LIST: {
				let query = '';
				query = querystring.encode({ ...params.filter, ...params.pagination });
				url = `${apiUrl}/${resource}/?${query}`;
				break;
			}
			case GET_ONE:
				url = `${apiUrl}/${resource}/id/${params.id}`;
				break;
			case GET_MANY: {
				url = `${apiUrl}/${resource}`;
				console.log(`Chamou o GET MANY. [ ${resource} ]`);
				console.log('url', url);
				break;
			}
			case GET_MANY_REFERENCE: {
				//console.log('Chamou o GET MANY REFERENCE.');
				url = `${apiUrl}/${resource}`;
				break;
			}
			case UPDATE:
				url = `${apiUrl}/${resource}/${params.id}`;
				options.method = 'PUT';
				options.body = JSON.stringify(params.data);
				break;
			case CREATE:
				url = `${apiUrl}/${resource}`;
				options.method = 'POST';
				options.body = JSON.stringify(params.data);
				break;
			case DELETE:
				url = `${apiUrl}/${resource}/${params.id}`;
				options.method = 'DELETE';
				break;
			default:
				throw new Error(`Unsupported fetch action type ${type}`);
		}
		// console.log(url);
		// console.log(options);
		return { url, options };
	};

	const convertHTTPResponse = (response, type, resource, params) => {
		const { json, headers } = response;
		switch (type) {
			case GET_LIST:
				return {
					data: json.map(user => ({ ...user, id: user._id })),
					total: headers.get('X-Total-Count'),
				};
			case GET_MANY:
				console.log('resp: ', response);
				return {
					data: json.map(user => ({ ...user, id: user._id })),
					total: 1,
				};
			case GET_ONE:
				return {
					data: { ...json, id: json._id },
				};
			case GET_MANY_REFERENCE:
			case CREATE:
				return { data: { ...params.data, id: json.id } };
			case DELETE_MANY: {
				return { data: json || [] };
			}
			case UPDATE:
				return {
					data: { ...json, id: json._id },
				};
			default:
				return { data: json };
		}
	};

	return (type, resource, params) => {
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'PUT',
						body: JSON.stringify(params.data),
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}
		if (type === DELETE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'DELETE',
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}

		const { url, options } = convertDataRequestToHTTP(type, resource, params);
		return httpClient(url, options).then(response => {
			// console.log(url);
			// console.log(JSON.parse(response.body));
			return convertHTTPResponse(response, type, resource, params);
		});
	};
};
