import React from 'react';
import {
	ArrayInput,
	SimpleFormIterator,
	List,
	Datagrid,
	FormDataConsumer,
	TextField,
	Edit,
	AutocompleteInput,
	ReferenceInput,
	NumberInput,
	SimpleForm,
	TextInput,
	Filter,
	Create,
	BooleanField,
	Pagination,
	SelectInput,
	SaveButton,
	Toolbar,
	BooleanInput,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { validateRequired, validateFormCompany } from '../utils/validation';

const FormTitle = ({ record }) => {
	return <span>{`${record.type}`}</span>;
};

const FormFilter = props => (
	<Filter {...props}>
		<TextInput label="Empresa" source="companyName" alwaysOn />
		<SelectInput
			alwaysOn
			label="Tipo"
			source="type"
			choices={[
				{ id: 'Embarque', name: 'Embarque' },
				{ id: 'Desembarque', name: 'Desembarque' },
				{ id: 'IniciarAtendimento', name: 'Iniciar Atendimento' },
				{ id: 'EncaminharAtendimento', name: 'Encaminhar Atendimento' },
				{ id: 'FinalizarAtendimento', name: 'Finalizar Atendimento' },
				{ id: 'Ocorrencia', name: 'Ocorrência' },
				{ id: 'OcorrenciaPadrao', name: 'Ocorrência Padrão' },
			]}
		/>
	</Filter>
);

const FormPagination = props => (
	<Pagination rowsPerPageOptions={[10]} {...props} />
);

export const FormList = props => (
	<List
		{...props}
		pagination={<FormPagination />}
		perPage={10}
		filters={<FormFilter />}
		title="Formulários"
	>
		<Datagrid rowClick="edit">
			<TextField label="Empresa" source="companyId.name" />
			<TextField label="Tipo" source="type" />
			<TextField label="Título" source="title" />
			<BooleanField label="Ativo" source="active" />
		</Datagrid>
	</List>
);

const PostEditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

export const FormEdit = props => (
	<Edit title={<FormTitle />} {...props}>
		<SimpleForm toolbar={<PostEditToolbar />}>
			<ReferenceInput
				validate={validateFormCompany}
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput label="Título" source="title" fullWidth />
			<SelectInput
				label="Tipo"
				source="type"
				choices={[
					{ id: 'Embarque', name: 'Embarque' },
					{ id: 'Desembarque', name: 'Desembarque' },
					{ id: 'IniciarAtendimento', name: 'Iniciar Atendimento' },
					{ id: 'EncaminharAtendimento', name: 'Encaminhar Atendimento' },
					{ id: 'FinalizarAtendimento', name: 'Finalizar Atendimento' },
					{ id: 'FinalizarTrilha', name: 'Finalizar Trilha' },
					{ id: 'Ocorrencia', name: 'Ocorrência' },
					{ id: 'OcorrenciaPadrao', name: 'Ocorrência Padrão' },
				]}
			/>
			<BooleanInput label="Ativo" source="active" />
			{/* =============================================================== */}
			{/* ======================== FORM SECTIONS ======================== */}
			{/* =============================================================== */}
			<ArrayInput label="Seções do Formulário" source="formSections" fullWidth>
				<SimpleFormIterator>
					<TextInput label="Título" source="title" fullWidth />
					<BooleanInput label="Obrigatória" source="required" />
					<NumberInput label="Posição (Índice)" source="index" step={1} />
					<SelectInput
						label="Tipo"
						source="type"
						choices={[
							{ id: 'rating/star', name: 'Rating - Star' },
							{ id: 'rating/emoji', name: 'Rating - Emoji' },
							{ id: 'text/paragraph', name: 'Parágrafo' },
							{ id: 'text/subtitle', name: 'Subtítulo' },
							{ id: 'selection/radio', name: 'Radio Button' },
							{
								id: 'selection/alternativeRadio',
								name: 'Radio Button (Alternativo)',
							},
							{ id: 'selection/checkbox', name: 'Checkbox' },
						]}
					/>

					<FormDataConsumer>
						{({ formData, scopedFormData, record, getSource }) => {
							if (scopedFormData) {
								switch (scopedFormData.type) {
									case 'rating/star':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção númerica
													entre um conjunto de números
												</Typography>

												<ArrayInput
													label="Conjunto de números"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'rating/emoji':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção entre uma
													escala de palavras e/ou ícones.
													<br />
													<a
														href="https://callstack.github.io/react-native-paper/icons.html"
														target="blank"
													>
														Ícones disponíveis
													</a>
												</Typography>

												<ArrayInput
													label="Ícones"
													source={getSource('icons')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput
															label="Nome do ícone"
															source="iconName"
														/>
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'text/paragraph':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário escreva uma resposta
												</Typography>
												<br />
											</div>
										);
									case 'selection/alternativeRadio':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção entre um
													conjunto de palavras e/ou ícones.
													<br />
													<a
														href="https://callstack.github.io/react-native-paper/icons.html"
														target="blank"
													>
														Ícones disponíveis
													</a>
												</Typography>
												<ArrayInput
													label="Opções"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="Nome do ícone" source="icon" />
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'selection/radio':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção entre um
													conjunto de palavras.
												</Typography>

												<BooleanInput
													label="Vertical"
													source={getSource('vertical')}
												/>

												<ArrayInput
													label="Opções"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'selection/checkbox':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione várias opções entre um
													conjunto de palavras.
												</Typography>
												<ArrayInput
													label="Opções"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									default:
										return null;
								}
							}
						}}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Edit>
);

export const FormCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput
				validate={validateRequired}
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>

			<TextInput label="Título" source="title" fullWidth />
			<SelectInput
				label="Tipo"
				source="type"
				choices={[
					{ id: 'Embarque', name: 'Embarque' },
					{ id: 'Desembarque', name: 'Desembarque' },
					{ id: 'IniciarAtendimento', name: 'Iniciar Atendimento' },
					{ id: 'EncaminharAtendimento', name: 'Encaminhar Atendimento' },
					{ id: 'FinalizarAtendimento', name: 'Finalizar Atendimento' },
					{ id: 'FinalizarTrilha', name: 'Finalizar Trilha' },
					{ id: 'Ocorrencia', name: 'Ocorrência' },
				]}
			/>
			<BooleanInput label="Ativo" source="active" />
			<ArrayInput label="Seções do Formulário" source="formSections" fullWidth>
				<SimpleFormIterator>
					<TextInput label="Título" source="title" fullWidth />
					<BooleanInput label="Obrigatória" source="required" />
					<NumberInput label="Posição (Índice)" source="index" step={1} />
					<SelectInput
						label="Tipo"
						source="type"
						choices={[
							{ id: 'rating/star', name: 'Rating - Star' },
							{ id: 'rating/emoji', name: 'Rating - Emoji' },
							{ id: 'text/paragraph', name: 'Parágrafo' },
							{ id: 'text/subtitle', name: 'Subtítulo' },
							{ id: 'selection/radio', name: 'Radio Button' },
							{
								id: 'selection/alternativeRadio',
								name: 'Radio Button (Alternativo)',
							},
							{ id: 'selection/checkbox', name: 'Checkbox' },
						]}
					/>

					<FormDataConsumer>
						{({ formData, scopedFormData, record, getSource }) => {
							if (scopedFormData) {
								switch (scopedFormData.type) {
									case 'rating/star':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção númerica
													entre um conjunto de números
												</Typography>

												<ArrayInput
													label="Conjunto de números"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label=" " />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'rating/emoji':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção entre uma
													escala de palavras e/ou ícones.
													<br />
													<a
														href="https://callstack.github.io/react-native-paper/icons.html"
														target="blank"
													>
														Ícones disponíveis
													</a>
												</Typography>

												<ArrayInput
													label="Ícones"
													source={getSource('icons')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput
															label="Nome do ícone"
															source="iconName"
														/>
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'text/paragraph':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário escreva uma resposta
												</Typography>
												<br />
											</div>
										);
									case 'selection/alternativeRadio':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção entre um
													conjunto de palavras e/ou ícones.
													<br />
													<a
														href="https://callstack.github.io/react-native-paper/icons.html"
														target="blank"
													>
														Ícones disponíveis
													</a>
												</Typography>
												<ArrayInput
													label="Opções"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="Nome do ícone" source="icon" />
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'selection/radio':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione uma opção entre um
													conjunto de palavras.
												</Typography>

												<BooleanInput
													label="Vertical"
													source={getSource('vertical')}
												/>

												<ArrayInput
													label="Opções"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									case 'selection/checkbox':
										return (
											<div>
												<Typography
													variant="subtitle1"
													style={{ color: '#555' }}
												>
													Permite que o usuário selecione várias opções entre um
													conjunto de palavras.
												</Typography>
												<ArrayInput
													label="Opções"
													source={getSource('options')}
													fullWidth
												>
													<SimpleFormIterator>
														<TextInput label="Descrição" source="label" />
													</SimpleFormIterator>
												</ArrayInput>
											</div>
										);
									default:
										return null;
								}
							}
						}}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Create>
);
