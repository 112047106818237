import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	DateField,
	ImageField,
	Pagination,
	Filter,
	TextInput,
	ReferenceInput,
	SelectInput,
	DateInput,
} from 'react-admin';

const UserFilter = props => (
	<Filter {...props}>
		<ReferenceInput
			label="Atendimento"
			source="atendimento"
			reference="service-admin"
			alwaysOn
		>
			<SelectInput
				optionText={choice => choice.title}
			/>
		</ReferenceInput>
		<TextInput label="Usuário" source="fullName" alwaysOn />
		<TextInput label="Empresa" source="companyName" alwaysOn />
		<DateInput label="Data Início" source="createdAtStart" alwaysOn />
		<DateInput label="Data Fim" source="createdAtEnd" alwaysOn />
	</Filter>
);

const NotePagination = props => (
	<Pagination rowsPerPageOptions={[10]} {...props} />
);

export const NoteList = props => (
	<List
		{...props}
		bulkActionButtons={null}
		pagination={<NotePagination />}
		perPage={10}
		filters={<UserFilter />}
		title="Notas"
	>
		<Datagrid rowClick="edit">
			<TextField source="content" />
			<ReferenceField source="userId" reference="user-admin">
				<TextField source="fullName" />
			</ReferenceField>
			<DateField showTime locales="pt-BR" label="Data" source="createdAt" />
			<TextField source="stepTitle" label="Título do passo" />
			<TextField source="stepIndex" label="Index do passo" />
			<ImageField source="stepImg" label="Imagem do passo" />
		</Datagrid>
	</List>
);
