import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	Pagination,
	DateInput,
	CardActions,
	DateField,
	ExportButton,
	Filter,
	TextInput,
	downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import FormAnswerPanel from '../components/FormAnswerPanel';

const exporter = (records, fetchRelatedRecords) => {
	const convertConfig = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	};

	fetchRelatedRecords(records, 'userId', 'user-admin').then(users => {
		fetchRelatedRecords(records, 'workplaceId', 'agency-admin').then(
			workplaces => {
				let columns = ['user', 'createdAt', 'trilhas', 'company', 'workplace'];
				const data = records.map(record => {
					let sections = [];
					if (record.answerSections) {
						sections = record.answerSections;
					} else if (record.formAnswerId.answerSections) {
						sections = record.formAnswerId.answerSections;
					}

					const answers = sections
						.sort((a, b) => {
							return a.sectionId.index - b.sectionId.index;
						})
						.map(section => {
							let { title } = section.sectionId;

							if (!title) {
								title = `Pergunta ${section.sectionId._id}`;
							}
							let answer = '';

							switch (section.sectionId.type) {
								case 'text/paragraph':
								case 'rating/emoji':
								case 'rating/star':
								case 'selection/checkbox':
								case 'selection/radio':
									answer = section.answer;
									break;
								case 'selection/alternativeRadio':
									const result = section.sectionId.options[section.answer];
									if (result.icon && result.label) {
										answer = `${result.icon} - ${result.label}`;
									} else if (result.icon) {
										answer = `${result.icon}`;
									} else {
										answer = `${result.label}`;
									}
									break;
								default:
									answer = '';
							}

							const obj = {
								title,
								answer,
							};

							return obj;
						});

					//console.log('record:', record);
					//console.log('record.userId:', record.userId);
					//console.log('users:', users);
					//console.log('users[record.userId]:', users[record.userId]);
					//console.log('fullName:', users[record.userId].fullName);

					const result = {
						user: users[record.userId].fullName,
						createdAt: new Date(
							parseInt(record.createdAt, 10)
						).toLocaleDateString('pt-BR', convertConfig),
					};

					if(workplaces[record.workplaceId]){
						result.company = workplaces[record.workplaceId].companyId.name;
						result.workplace = workplaces[record.workplaceId].number;
					}

					//console.log('Lendo o trilhas.');
					// Add trilhas percorridas to CSV
					if(record.respostasTrilhas){
						const trilhas = record.respostasTrilhas;
						let trilhasPercorridas = '';
						//console.log('=> trilhas:', trilhas);
						trilhas.forEach(trilha => {
							//console.log('trilhas:', trilha.trilhaId);
							if(trilha.trilhaId !== null) trilhasPercorridas += `${trilha.trilhaId.title}, `;
						});
						result.trilhas = trilhasPercorridas;
					}

					//console.log('Lendo o answers.');
					answers.forEach(answer => {
						//console.log('answer:', answer.answer);
						result[answer.title] = answer.answer.replace(/[\n\r]/g, ''); // Remove new line chars
						columns.push(answer.title);

						// Insert title only if it has not been included yet
						// if (!columns.includes(answer.title)) {
						//   columns.push(answer.title);
						// }
					});

					return result;
				});

				// Remove duplicate titles
				columns = [...new Set(columns)];

				const csv = convertToCSV(data, {
					columns,
					encoding: 'utf-8',
					delimiter: ';',
				});
				downloadCSV(
					csv,
					`Respostas - ${new Date().toLocaleDateString('pt-BR', convertConfig)}`
				);
			}
		);
	});
};

const UserFilter = props => (
	<Filter {...props}>
		<TextInput label="Nome usuário" source="fullName" alwaysOn />
		<TextInput label="Empresa" source="companyName" alwaysOn />
		{props.resource === 'form-answer-admin/FinalizarTrilha' ? (
			<TextInput label="Trilha" source="trilhaName" alwaysOn />
		) : null}
		<DateInput label="Data Início" source="createdAtStart" alwaysOn />
		<DateInput label="Data Fim" source="createdAtEnd" alwaysOn />
	</Filter>
);

const FormAnswerPagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

const FormAnswerActions = ({ currentSort, filterValues, resource }) => (
	<CardActions>
		<ExportButton
			resource={resource}
			sort={currentSort}
			filter={filterValues}
			exporter={exporter}
			maxResults={1000000}
		/>
	</CardActions>
);

export const FormAnswerList = props => {
	return (
		<List
			{...props}
			actions={<FormAnswerActions />}
			bulkActionButtons={null}
			pagination={<FormAnswerPagination />}
			filters={<UserFilter />}
			title={props.options.label}
		>
			<Datagrid rowClick="edit" expand={<FormAnswerPanel />}>
				<ReferenceField
					label="Empresa"
					source="formId.companyId"
					reference="company-admin"
					allowEmpty
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Workplace"
					source="workplaceId"
					reference="agency-admin"
					allowEmpty
				>
					<TextField source="number" />
				</ReferenceField>
				<ReferenceField
					label="Usuário"
					source="userId"
					reference="user-admin"
					link={false}
				>
					<TextField source="fullName" />
				</ReferenceField>
				{props.resource === 'form-answer-admin/FinalizarTrilha' ? (
					<TextField label="Trilha" source="trilhaId.title" />
				) : null}
				<DateField showTime locales="pt-BR" label="Data" source="createdAt" />
			</Datagrid>
		</List>
	);
};
