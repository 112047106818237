import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ChipField,
  ImageField,
  Create,
  ImageInput,
  SelectField,
  DateInput,
  BooleanInput,
  ReferenceArrayInput,
  FormDataConsumer,
  SelectArrayInput,
  ReferenceArrayField,
  Filter,
  SingleFieldList,
  Pagination,
} from 'react-admin';
import AgencyField from '../components/AgencyField';

// Validation functions
import {
  validateName,
  validateEmail,
  validateAccess,
  validateSex,
} from '../utils/validation';

const UserTitle = ({ record }) => {
	let title = 'Usuário';
	if (record.gender === 'Female') {
		title = 'Usuária';
	}
	return <span>{record ? `${title} ${record.fullName}` : ''}</span>;
};

const UserFilter = props => (
	<Filter {...props}>
		<TextInput label="Buscar por nome" source="fullName" alwaysOn />
	</Filter>
);

const UserPagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

export const UserList = props => (
	<List {...props}
		pagination={<UserPagination />}
		filters={<UserFilter />}
		title="Usuários"
	>
		<Datagrid rowClick="edit">
			<TextField label="Nome Completo" source="fullName" />
			<EmailField source="email" />
			<TextField label="Telefone" source="phone" />
			<SelectField
				label="Permissão"
				source="rules"
				choices={[
					{ id: 'Sudo', name: 'Administrador' },
					{ id: 'Senior', name: 'Senior' },
				]}
			/>
			<ChipField label="Acesso" source="rules" />
			<SelectField
				label="Gênero"
				source="gender"
				choices={[
					{ id: 'Male', name: 'Masculino' },
					{ id: 'Female', name: 'Feminino' },
				]}
			/>
			<ImageField label="Foto" source="photo" />
			<ReferenceArrayField label="Workplaces" source="agency" reference="agency-admin" >
				<SingleFieldList>
					<AgencyField />
				</SingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	</List>
);

export const UserEdit = props => (
	<Edit title={<UserTitle />} {...props}>
		<SimpleForm>

			<ImageInput
				multiple={false}
				source="picture"
				label="Foto de Perfil"
				accept="image/*"
				placeholder={<p>Clique aqui ou arraste um arquivo</p>}
			>
				<ImageField source="photo" />
			</ImageInput>

			<FormDataConsumer>
				{({ formData }) => {
					if (!formData.picture) {
						return (
							<div>
								<img
									src={formData.photo}
									alt={formData.name}
									style={{ height: 200 }}
								/>
							</div>
						);
					}
				}}
			</FormDataConsumer>

			<SelectInput
				label="Permissão"
				source="rules"
				validate={validateAccess}
				choices={[
					{ id: 'Sudo', name: 'Administrador' },
					{ id: 'Senior', name: 'Senior' },
				]}
			/>

			<TextInput validate={validateName} label="Nome" source="name" />

			<TextInput
				validate={validateName}
				label="Nome completo"
				source="fullName"
			/>

			<TextInput validate={validateEmail} label="Email" source="email" />

			<TextInput label="Telefone" source="phone" />

			<SelectInput
				validate={validateSex}
				label="Gênero"
				source="gender"
				choices={[
					{ id: 'Male', name: 'Masculino' },
					{ id: 'Female', name: 'Feminino' },
				]}
			/>

			<DateInput label="Data de Nascimento" source="dateOfBirth" />

			<ReferenceArrayInput
				fullWidth
				label="Workplaces"
				source="agency"
				reference="agency-admin"
				perPage={100}
			>
				<SelectArrayInput optionText={choice =>
					`[${choice.companyId.name} - ${choice.number}] ${choice.address}`}
				/>
			</ReferenceArrayInput>

			<BooleanInput label="Ativo" source="active" />

		</SimpleForm>
	</Edit>
);

export const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">

			<ImageInput
				multiple={false}
				source="picture"
				label="Foto de Perfil"
				accept="image/*"
				placeholder={<p>Clique aqui ou arraste um arquivo</p>}
			>
				<ImageField />
			</ImageInput>

			<SelectInput
				label="Permissão"
				source="rules"
				validate={validateAccess}
				choices={[
					{ id: 'Sudo', name: 'Administrador' },
					{ id: 'Senior', name: 'Senior' },
				]}
			/>

			<TextInput validate={validateName} label="Nome" source="name" />

			<TextInput
				validate={validateName}
				label="Nome completo"
				source="fullName"
			/>

			<TextInput validate={validateEmail} label="Email" source="email" />

			<TextInput label="Telefone" source="phone" />

			<SelectInput
				label="Gênero"
				source="gender"
				validate={validateSex}
				choices={[
					{ id: 'Male', name: 'Masculino' },
					{ id: 'Female', name: 'Feminino' },
				]}
			/>

			<DateInput label="Data de Nascimento" source="dateOfBirth" />

			<ReferenceArrayInput
				fullWidth
				label="Workplaces"
				source="agency"
				reference="agency-admin"
				perPage={100}
			>
				<SelectArrayInput
					optionText={choice =>
					`[${choice.companyId.name} - ${choice.number}] ${choice.address}`
					}
				/>
			</ReferenceArrayInput>

			<BooleanInput label="Ativo" source="active" />

		</SimpleForm>
	</Create>
);
