import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  ReferenceField,
  Pagination,
  DateInput,
  SelectInput,
  CardActions,
  DateField,
  ExportButton,
  Filter,
  TextInput,
  downloadCSV,
  SimpleForm,
  Create,
  DateTimeInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  AutocompleteInput,
  ReferenceInput,
  EditButton,
  CreateButton,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Typography, withStyles } from '@material-ui/core';
import { values } from 'redux-form';
import AgencyField from '../components/AgencyField';
import OptionsField from '../components/OptionsField';
import FormAnswerPanel from '../components/FormAnswerPanel';

import {
  validateName,
  validateEmail,
  validateAccess,
  validateRequired,
  validateSex,
} from '../utils/validation';

const exporter = (records, fetchRelatedRecords) => {
	const convertConfig = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	};

	console.log('Chamou o export checkpoints.');
	//console.log('records', records);
	//console.log('fetchRelatedRecords', fetchRelatedRecords);

	fetchRelatedRecords(records, 'userId', 'user-admin').then(users => {

		fetchRelatedRecords(records, 'workplaceId', 'agency-admin').then(
			workplaces => {
				let columns = ['user', 'createdAt', 'type', 'company', 'workplace'];
				const data = records.map(record => {
					let sections = [];
					if(record.answerSections)		sections = record.answerSections;
					else if(record.formAnswerId)	sections = record.formAnswerId.answerSections;

					const answers = sections
						.sort((a, b) => {
							return a.sectionId.index - b.sectionId.index;
						})
						.map(section => {
							let { title } = section.sectionId;
							if (!title) {
								title = `Pergunta ${section.sectionId._id}`;
							}
							let answer = '';
							switch (section.sectionId.type) {
								case 'text/paragraph':
								case 'rating/emoji':
								case 'rating/star':
								case 'selection/radio':
									answer = section.answer;
								break;
								case 'selection/alternativeRadio':
									const result = section.sectionId.options[section.answer];
									if (result.icon && result.label) {
										answer = `${result.icon} - ${result.label}`;
									} else if (result.icon) {
										answer = `${result.icon}`;
									} else {
										answer = `${result.label}`;
									}
								break;
								default:
									answer = '';
							}
							const obj = { title, answer };
							return obj;
						});

					//console.log('users:', users);
					//console.log('fullName:', users[record.userId].fullName);
					const result = {
						user: users[record.userId].fullName,
						createdAt: new Date(
							parseInt(record.createdAt, 10)
						).toLocaleDateString('pt-BR', convertConfig),
						type: record.type === 0 ? 'Embarque' : 'Desembarque',
					};

					if (workplaces[record.agency._id]) {
						result.company = workplaces[record.agency._id].companyId.name;
						result.workplace = workplaces[record.agency._id].number;
					}

					answers.forEach(answer => {
						result[answer.title] = answer.answer.replace(/[\n\r]/g, ''); // Remove new line chars
						columns.push(answer.title);
						// Insert title only if it has not been included yet
						// if (!columns.includes(answer.title)) {
						//   columns.push(answer.title);
						// }
					});

					return result;
				});

		// Remove duplicate titles
		columns = [...new Set(columns)];

		const csv = convertToCSV(data, {
		  columns,
		  encoding: 'utf-8',
		  delimiter: ';',
		});
		downloadCSV(
		  csv,
		  `Checkpoints - ${new Date().toLocaleDateString(
			'pt-BR',
			convertConfig
		  )}`
		);
	  }
	);
	});
};

const exporter2 = (records) => {
	console.log('Chamou o export 2 checkpoints.');
	console.log('records', records);
	//console.log('fetchRelatedRecords', fetchRelatedRecords);

	return;
};

const toolbarStyles = {
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
};

// modal para confirmar delete de checkpoint
const CustomToolbar = withStyles(toolbarStyles)(props => (
	<Toolbar {...props}>
		<SaveButton />
		<DeleteButton undoable={false} color="primary" />
	</Toolbar>
));

const UserFilter = props => (
	<Filter {...props}>
		<TextInput label="Nome usuário" source="fullName" alwaysOn />
		<TextInput label="Empresa" source="companyName" alwaysOn />
		<DateInput label="Data Início" source="createdAtStart" alwaysOn />
		<DateInput label="Data Fim" source="createdAtEnd" alwaysOn />
		<SelectInput
			alwaysOn
			label="Tipo"
			source="type"
			choices={[
				{ id: 0, name: 'Embarque' },
				{ id: 1, name: 'Desembarque' },
			]}
		/>
	</Filter>
);

const CheckpointPagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

//export
const CheckpointActions = ({basePath, currentSort, filterValues, resource,}) => (
	<CardActions>
		<ExportButton
			resource={resource}
			sort={currentSort}
			filter={filterValues}
			exporter={exporter}
			maxResults={1000000}
		/>
		<CreateButton
			basePath={basePath}
			resource="checkpoint-admin"
		/>
	</CardActions>
);

//export
const CheckpointActionsExport = ({currentSort, filterValues, resource,}) => (
	<CardActions>
		<ExportButton
			resource={resource}
			sort={currentSort}
			filter={filterValues}
			exporter={exporter}
			maxResults={1000000}
		/>
	</CardActions>
);

export const CheckpointList = props => (
	<List
		{...props}
		actions={<CheckpointActions />}
		bulkActionButtons={<CheckpointActionsExport />}
		pagination={<CheckpointPagination />}
		perPage={10}
		filters={<UserFilter />}
		title="Embarques/Desembarques"
	>
		<Datagrid rowClick="edit" expand={<FormAnswerPanel />}>

			<TextField label="Empresa" source="agency.companyId.name" />
			<TextField label="Workplace" source="agency.number" />

			<ReferenceField source="userId" reference="user-admin" linkType={false}>
				<TextField source="fullName" />
			</ReferenceField>

			<OptionsField label="Tipo" source="type" options={['Embarque', 'Desembarque']} />
			<DateField showTime locales="pt-BR" label="Data" source="createdAt" />
			<EditButton />

		</Datagrid>
	</List>
);

const CheckpointTitle = ({ record }) => {
	return record.type === 0 ? (
		<span>Correção - Embarque</span>
	) : (
		<span>Correção - Desembarque</span>
	);
};

//export
const EmpresaName = ({ record }) => {
	const company = record.agency.companyId.name.toString();
	return company;
};

export const CheckpointCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">

			<ReferenceInput
				label="Usuário"
				source="userId"
				reference="user-admin"
				filterToQuery={searchText => ({ fullName: searchText })}
				alwaysOn
			>
				<AutocompleteInput optionText="fullName" />
			</ReferenceInput>

			<SelectInput
				label="Tipo"
				source="type"
				reference="form-admin"
				choices={[
					{ id: 0, name: 'Embarque' },
					{ id: 1, name: 'Desembarque' },
				]}
			/>

			<ReferenceInput
				alwaysOn
				//fullWidth
				label="Workplaces"
				source="agency"
				reference="agency-admin"
				// filterToQuery={searchText => ({ address: searchText })}
				perPage={100}
			>
				<AutocompleteInput optionText={choice =>
					`[${choice.companyId.name} - ${choice.number}] ${choice.address}`}
				/>
			</ReferenceInput>

			{/* <ReferenceInput label="Formulário" source="formId" reference="form-admin">
				<SelectInput
					alwaysOn
					label="Formulário"
					source="formId"
					reference="form-admin"
					optionText={choice => `${choice.title}`}
				/>
			</ReferenceInput> */}

			<SelectInput
				label="Formulário"
				source="formId"
				reference="form-admin"
				// disabled="true"
				choices={[
					{ id: '62311a6384d9f362fd8db38d', name: 'Correção Embarque' },
					{ id: '62311aa184d9f362fd8db416', name: 'Correção Desembarque' },
				]}
			/>

			<TextInput
				label="Motivo"
				source="formSections.answer"
				reference="form-admin"
			/>

			<DateTimeInput
				label="Data Início"
				source="createdAt"
				reference="checkpoint-admin"
				defaultValue={new Date()}
				alwaysOn
			/>

		</SimpleForm>
	</Create>
);

export const CheckpointEdit = props => (
	<Edit title={<CheckpointTitle />} {...props}>
		<SimpleForm toolbar={<CustomToolbar />}>

			<OptionsField
				alwaysOn
				label="Tipo"
				source="type"
				reference="form-admin"
				options={['Embarque', 'Desembarque']}
			/>

			<ReferenceField source="userId" reference="user-admin" linkType={false}>
				<TextField source="fullName" />
			</ReferenceField>

			<ReferenceInput
				alwaysOn
				label="Workplace"
				source="agency"
				reference="agency-admin"
				perPage={100}
			>
				<SelectInput optionText={choice =>
					`[${choice.companyId.name} - ${choice.number}] ${choice.address}`}
				/>
			</ReferenceInput>

			<DateTimeInput
				label="Data Início"
				source="createdAt"
				reference="checkpoint-admin"
				defaultValue={new Date()}
				alwaysOn
			/>

		</SimpleForm>
	</Edit>
);
