import React from 'react';
import { Typography, Container, Divider } from '@material-ui/core';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import StarIcon from '@material-ui/icons/Star';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '16px 0 4px 0',
  },
  div: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#444',
    marginLeft: '8px',
  },
}));

const renderPanel = ({ record, classes }) => {
  let sections = [];
  if (record.answerSections) {
    sections = record.answerSections;
  } else if (record.formAnswerId) {
    sections = record.formAnswerId.answerSections;
  } else {
    return (
      <div>
        <p>Formulário não cadastrado</p>
      </div>
    );
  }

  let trilhaTitle = '';
  if (record.trilhaId) {
    trilhaTitle = record.trilhaId.title;
  }

  return (
    <div>
      <Container className={classes.root}>
        {/* Fim de Atendimento */}
        {record.respostasTrilhas && (
          <Typography variant="h5" component="h3">
            Trilhas finalizadas:
          </Typography>
        )}
        {record.respostasTrilhas &&
          record.respostasTrilhas.map(obj => {
            return <Typography component="p">{obj.trilhaId.title}</Typography>;
          })}

        {record.trilhasCanceladas && (
          <div>
            <br />
            <Typography variant="h5" component="h3">
              Trilhas canceladas:
            </Typography>
          </div>
        )}
        {record.trilhasCanceladas &&
          record.trilhasCanceladas.map(obj => {
            return <Typography component="p">{obj.title}</Typography>;
          })}

        {/* Fim de Trilha */}
        {trilhaTitle && (
          <Typography variant="h5" component="h3">
            Trilha: {trilhaTitle}
          </Typography>
        )}

        <br />

        <Typography variant="h5" component="h3">
          Respostas
        </Typography>

        <Divider />

        {sections
          .sort((a, b) => {
            return a.sectionId.index - b.sectionId.index;
          })
          .map(answer => {
            switch (answer.sectionId.type) {
              case 'text/paragraph':
                return (
                  <Container className={classes.root}>
                    <Typography variant="h6">
                      {answer.sectionId.title}
                    </Typography>
                    <Typography style={{ color: '#444' }} variant="body1">
                      {answer.answer}
                    </Typography>
                  </Container>
                );
              case 'selection/radio':
                return (
                  <Container className={classes.root}>
                    <Typography variant="h6">
                      {answer.sectionId.title}
                    </Typography>
                    <div className={classes.div}>
                      <FontDownloadIcon />
                      <Typography className={classes.text} variant="body1">
                        {answer.answer}
                      </Typography>
                    </div>
                  </Container>
                );
              case 'selection/alternativeRadio':
                const result = answer.sectionId.options[answer.answer];
                if (result) {
                  return (
                    <Container className={classes.root}>
                      <Typography variant="h6">
                        {answer.sectionId.title}
                      </Typography>
                      {result.label && (
                        <div className={classes.div}>
                          <FontDownloadIcon />
                          <Typography className={classes.text} variant="body1">
                            {result.label}
                          </Typography>
                        </div>
                      )}
                      {result.icon && (
                        <div className={classes.div}>
                          <AccountBoxIcon />
                          <Typography className={classes.text} variant="body1">
                            {result.icon}
                          </Typography>
                        </div>
                      )}
                    </Container>
                  );
                }

                return null;

              case 'rating/emoji':
                return (
                  <Container className={classes.root}>
                    <Typography variant="h6">
                      {answer.sectionId.title}
                    </Typography>
                    <div className={classes.div}>
                      <EmojiEmotionsIcon />
                      <Typography className={classes.text} variant="body1">
                        {answer.answer}
                      </Typography>
                    </div>
                  </Container>
                );
              case 'rating/star':
                return (
                  <Container className={classes.root}>
                    <Typography variant="h6">
                      {answer.sectionId.title}
                    </Typography>
                    <div className={classes.div}>
                      <StarIcon />
                      <Typography className={classes.text} variant="body1">
                        {answer.answer}
                      </Typography>
                    </div>
                  </Container>
                );
              case 'selection/checkbox':
                return (
                  <Container className={classes.root}>
                    <Typography variant="h6">
                      {answer.sectionId.title}
                    </Typography>
                    <div className={classes.div}>
                      <CheckBoxIcon />
                      <Typography className={classes.text} variant="body1">
                        {answer.answer}
                      </Typography>
                    </div>
                  </Container>
                );
              default:
                return null;
            }
          })}

        {record.perfilId && (
          <Typography
            variant="h5"
            component="h3"
            style={{ marginTop: 24, textDecoration: 'underline' }}
          >
            Perfil de Atendimento
          </Typography>
        )}

        {record.perfilId && renderPanel({ record: record.perfilId, classes })}
      </Container>
    </div>
  );
};

export default ({ record }) => {
  const classes = useStyles();
  return renderPanel({ record, classes });
};
