import React from 'react';
import {
	List,
	Datagrid,
	Filter,
	TextField,
	Edit,
	SimpleForm,
	TextInput,
	Create,
	SaveButton,
	Toolbar,
	Pagination,
} from 'react-admin';
import { validateRequired } from '../utils/validation';

const CompanyTitle = ({ record }) => {
	return <span>{`${record.name}`}</span>;
};

const CompanyFilter = props => (
	<Filter {...props}>
		<TextInput label="Nome" source="name" alwaysOn />
	</Filter>
);

const CompanyPagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

const PostEditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

export const CompanyList = props => (
	<List
		{...props}
		bulkActionButtons={null}
		perPage={10}
		pagination={<CompanyPagination />}
		filters={<CompanyFilter />}
		title="Empresas"
	>
		<Datagrid rowClick="edit">
			<TextField label="Nome" source="name" />
			<TextField label="Título posição" source="positionTitle" />
		</Datagrid>
	</List>
);

export const CompanyEdit = props => (
	<Edit title={<CompanyTitle />} {...props}>
		<SimpleForm toolbar={<PostEditToolbar />}>
			<TextInput label="Nome" validate={validateRequired} source="name" />
			<TextInput
				label="Título posição"
				validate={validateRequired}
				source="positionTitle"
			/>
		</SimpleForm>
	</Edit>
);

export const CompanyCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Nome" validate={validateRequired} source="name" />
			<TextInput
				label="Título posição"
				validate={validateRequired}
				source="positionTitle"
			/>
		</SimpleForm>
	</Create>
);
