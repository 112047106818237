import React from 'react';
import { Admin, Resource, fetchUtils, ListGuesser } from 'react-admin';
import createHistory from 'history/createBrowserHistory';

// Icons
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';
import DepartureBoardRoundedIcon from '@material-ui/icons/DepartureBoardRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import PanToolIcon from '@material-ui/icons/PanTool';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';

import provider from './dataProvider';
import addUploadFeature from './utils/addUploadFeature';

import { UserList, UserEdit, UserCreate } from './models/users';
import { AgencyList, AgencyEdit, AgencyCreate } from './models/agency';
import { CompanyList, CompanyEdit, CompanyCreate } from './models/company';
import { ServiceList, ServiceEdit, ServiceCreate } from './models/service';
import { FormList, FormEdit, FormCreate } from './models/form';
import {
	OrientationList,
	OrientationEdit,
	OrientationCreate,
} from './models/orientation';
import { NoteList } from './models/note';
import {
	CheckpointList,
	CheckpointCreate,
	CheckpointEdit,		// adicionado pelo Eduardo.
} from './models/checkpoint';
import { FormAnswerList } from './models/form-answer';
import authProvider from './authProvider';
import dashboard from './dashboard';

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		// eslint-disable-next-line no-param-reassign
		options.headers = new Headers({ Accept: 'application/json' });
	}
	const token = localStorage.getItem('token');
	options.headers.set('x-access-token', `${token}`);
	return fetchUtils.fetchJson(url, options);
};

let dataProvider = provider(process.env.REACT_APP_API_ROOT, httpClient);
dataProvider = addUploadFeature(dataProvider);

const history = createHistory({ basename: '/admin' });

const App = () => (
	<Admin
		title="Labora"
		history={history}
		dashboard={dashboard}
		authProvider={authProvider}
		dataProvider={dataProvider}
	>
		<Resource name="user-admin" options={{ label: 'Usuários' }} icon={PeopleRoundedIcon} list={UserList} edit={UserEdit} create={UserCreate} />
		<Resource name="company-admin" options={{ label: 'Empresas' }} icon={BusinessRoundedIcon} list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />
		<Resource name="agency-admin" options={{ label: 'Workplaces' }} icon={WorkRoundedIcon} list={AgencyList} edit={AgencyEdit} create={AgencyCreate} />
		<Resource name="orientation-admin" options={{ label: 'Orientações' }} icon={PanToolIcon} list={OrientationList} edit={OrientationEdit} create={OrientationCreate} />
		<Resource name="form-admin" options={{ label: 'Formulários' }} icon={ListAltRoundedIcon} list={FormList} edit={FormEdit} create={FormCreate} />
		{/* <Resource name="empty/1" options={{ label: ' ' }} icon={ArrowDropDownSharpIcon} list={ListGuesser} /> */}
		<Resource name="service-admin" options={{ label: 'Atendimentos' }} icon={EmojiPeopleRoundedIcon} list={ServiceList} edit={ServiceEdit} create={ServiceCreate} />
		{/* <Resource name="empty/2" options={{ label: ' ' }} icon={ArrowDropDownSharpIcon} list={ListGuesser} /> */}
		<Resource name="checkpoint-admin" options={{ label: 'Embarques/Desembarques' }} icon={DepartureBoardRoundedIcon} list={CheckpointList} create={CheckpointCreate} edit={CheckpointEdit} />
		{/* <Resource name="empty/3" options={{ label: ' ' }} list={ListGuesser} icon={ArrowDropDownSharpIcon} /> */}
		<Resource name="form-answer-admin/IniciarAtendimento" options={{ label: 'Perfil de Atendimento' }} icon={FaceRoundedIcon} list={FormAnswerList} title="Perfil de Atendimento" />
		<Resource name="form-answer-admin/FinalizarTrilha" options={{ label: 'Fim de Trilha' }} icon={TimelineRoundedIcon} list={FormAnswerList} />
		<Resource name="form-answer-admin/FinalizarAtendimento" options={{ label: 'Fim de Atendimento' }} icon={CheckRoundedIcon} list={FormAnswerList} />
		<Resource name="form-answer-admin/EncaminharAtendimento" options={{ label: 'Encaminhamentos' }} icon={ReplyRoundedIcon} list={FormAnswerList} />
		{/* <Resource name="empty/4" options={{ label: ' ' }} list={ListGuesser} icon={ArrowDropDownSharpIcon} /> */}
		<Resource name="form-answer-admin/Ocorrencia" options={{ label: 'Ocorrências' }} icon={WarningRoundedIcon} list={FormAnswerList} />
		<Resource name="note-admin" options={{ label: 'Notas' }} icon={CreateRoundedIcon} list={NoteList} />
	</Admin>
);

export default App;
