import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Chip, Container } from '@material-ui/core';

const styles = {
  container: {
    padding: '4px 0 4px 0',
  },
};

const OptionsField = ({ record = {}, options = [], source, classes }) => {
  const label = options[record[source]];

  return (
    <Container className={classes.container} maxWidth="xl">
      <Chip label={label} />
    </Container>
  );
};

export default withStyles(styles)(OptionsField);
