import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Chip, Container } from '@material-ui/core';

const styles = {
  container: {
    padding: '4px 0 4px 0',
  },
};

const AgencyField = ({ record = {}, source, classes }) => (
  <Container className={classes.container} maxWidth="xl">
    <Chip label={`${record.number} - ${record.address}`} />
  </Container>
);

export default withStyles(styles)(AgencyField);
