import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
} from 'react-admin';

export const validateAccess = [
  required('Selecione uma das opções'),
  choices(['Sudo', 'Senior']),
];

export const validateName = [
  required('Preencha o nome'),
  minLength(3, 'O nome deve ter pelo menos três letras'),
];

export const validateEmail = [
  required('Preencha o email'),
  email('Este email não é válido'),
];

export const validateSex = [
  required('Selecione uma das opções'),
  choices(['Male', 'Female']),
];

export const validateRequired = [required('Este campo é obrigatório')];

const formCompanyValidation = (value, allValues) => {
  if (allValues.type === 'OcorrenciaPadrao') {
    return undefined;
  }
  if (!value) {
    return 'Este campo é obrigatório';
  }
  return undefined;
};

export const validateFormCompany = [formCompanyValidation];
