/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const addUploadFeature = requestHandler => async (type, resource, params) => {
  if (
    (type === 'CREATE' || type === 'UPDATE') &&
    (resource === 'user-admin' || resource === 'service-admin')
  ) {
    if (resource === 'user-admin') {
      let base64Pic;

      if (params.data.picture) {
        base64Pic = await convertFileToBase64(params.data.picture);
      }

      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          picture: base64Pic,
        },
      });
    }

    const stepsUpdated = [];

    if (params.data.steps) {
      for (let i = 0; i < params.data.steps.length; i += 1) {
        let base64Pic;
        if (params.data.steps[i].picture) {
          // eslint-disable-next-line no-await-in-loop
          base64Pic = await convertFileToBase64(params.data.steps[i].picture);
          stepsUpdated.push({ ...params.data.steps[i], picture: base64Pic });
        } else {
          stepsUpdated.push({ ...params.data.steps[i] });
        }
      }
    }

    return requestHandler(type, resource, {
      ...params,
      data: {
        ...params.data,
        steps: stepsUpdated,
      },
    });
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
