import React from 'react';
import {
	List,
	Datagrid,
	Filter,
	TextField,
	Edit,
	SimpleForm,
	TextInput,
	Create,
	ReferenceInput,
	AutocompleteInput,
	Pagination,
	Toolbar, SaveButton,
} from 'react-admin';
import { validateRequired } from '../utils/validation';

const AgencyTitle = ({ record }) => {
	return (
		<span>
			Workplace {`[${record.number}] - `}
			{record ? `${record.address}` : ''}
		</span>
	);
};

const AgencyFilter = props => (
	<Filter {...props}>
		<TextInput label="Empresa" source="companyName" alwaysOn />
		<TextInput label="Número" source="number" alwaysOn />
		<TextInput label="Endereço" source="address" alwaysOn />
	</Filter>
);

const AgencyPagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

export const AgencyList = props => (
	<List
		{...props}
		bulkActionButtons={null}
		perPage={10}
		pagination={<AgencyPagination />}
		filters={<AgencyFilter />}
		title="Workplaces"
	>
		<Datagrid rowClick="edit">
			<TextField label="Empresa" source="companyId.name" />
			<TextField label="Número" source="number" />
			<TextField label="Endereço" source="address" />
			<TextField label="Região" source="region" />
		</Datagrid>
	</List>
);

const PostEditToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

export const AgencyEdit = props => (
	<Edit title={<AgencyTitle />} {...props} >
		<SimpleForm toolbar={<PostEditToolbar />} >
			<ReferenceInput
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput label="ID" validate={validateRequired} source="number" />
			<TextInput
				label="Endereço"
				validate={validateRequired}
				source="address"
			/>
			<TextInput label="Região" validate={validateRequired} source="region" />
		</SimpleForm>
	</Edit>
);

export const AgencyCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput
				validate={validateRequired}
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput label="ID" validate={validateRequired} source="number" />
			<TextInput
				label="Endereço"
				validate={validateRequired}
				source="address"
			/>
			<TextInput label="Região" validate={validateRequired} source="region" />
		</SimpleForm>
	</Create>
);
