import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	Edit,
	Filter,
	SimpleForm,
	TextInput,
	ReferenceField,
	AutocompleteInput,
	ReferenceInput,
	Create,
	BooleanField,
	BooleanInput,
	Pagination,
	NumberInput,
	ArrayInput,
	SimpleFormIterator,
	FormDataConsumer,
	ImageField,
	ImageInput,
} from 'react-admin';

const ServiceTitle = ({ record }) => {
	return <span>{`${record.title}`}</span>;
};

const ServiceFilter = props => (
	<Filter {...props}>
		<TextInput label="Título" source="title" alwaysOn />
	</Filter>
);

const ServicePagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

export const ServiceList = props => (
	<List
		{...props}
		pagination={<ServicePagination />}
		filters={<ServiceFilter />}
		perPage={10}
		title="Atendimentos"
	>
		<Datagrid rowClick="edit">
			<ReferenceField
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<TextField label="Nome" source="name" />
			</ReferenceField>
			<TextField label="Título" source="title" />
			<BooleanField label="Ativo" source="active" />
			<TextField source="index" />
			<TextField label="Contido na pasta" source="serviceMasterName" />
			<BooleanField label="Pasta" source="haveChildsServices" />
		</Datagrid>
	</List>
);

export const ServiceEdit = props => (
	<Edit title={<ServiceTitle />} {...props}>
		<SimpleForm>
			<ReferenceInput 
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput label="Título" source="title" />
			<NumberInput label="Posição (Índice)" source="index" />
			<TextInput label="Pasta" source="serviceMasterName" />
			<BooleanInput label="Ativo" source="active" />
			<BooleanInput label="É uma pasta?" source="haveChildsServices" />
			<ArrayInput label="Passos do atendimento" source="steps" fullWidth>
				<SimpleFormIterator>
					<ImageInput
						multiple={false}
						source="picture"
						label="Imagem do passo"
						accept="image/*"
						placeholder={<p>Clique aqui ou arraste um arquivo</p>}
					>
						<ImageField source="img" />
					</ImageInput>
					<FormDataConsumer>
						{({ getSource, scopedFormData }) => {
							console.log('Chamou o form old.');
							console.log(scopedFormData);
							if (scopedFormData) {
								return (
									<div>
										{!scopedFormData.picture && (
											<div>
												<img
													src={scopedFormData.img}
													alt={scopedFormData.index}
													style={{ height: 200 }}
												/>
											</div>
										)}
										<BooleanInput label="ativo" source={getSource('active')} />
										<NumberInput label="index" source={getSource('index')} />
										<TextInput
											label="Texto do passo"
											source={getSource('text')}
											fullWidth
										/>
										<TextInput
											label="Título do passo"
											source={getSource('title')}
											fullWidth
										/>
										<TextInput
											label="Informações do passo"
											source={getSource('info')}
											fullWidth
										/>
									</div>
								);
							}
							return null;
						}}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Edit>
);

export const ServiceCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput
				label="Empresa"
				source="companyId"
				reference="company-admin"
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput label="Título" source="title" />
			<NumberInput label="Posição (Índice)" source="index" />
			<TextInput label="Pasta" source="serviceMasterName" />
			<BooleanInput label="Ativo" source="active" />
			<BooleanInput label="É uma pasta?" source="haveChildsServices" />
			<ArrayInput label="Passos do atendimento" source="steps" fullWidth>
				<SimpleFormIterator>
					<ImageInput
						multiple={false}
						source="picture"
						label="Imagem do passo"
						accept="image/*"
						placeholder={<p>Clique aqui ou arraste um arquivo</p>}
					>
						<ImageField source="img" />
					</ImageInput>
					<FormDataConsumer>
						{({ getSource, scopedFormData }) => {
							return (
								<div>
									{!scopedFormData.picture && (
										<div>
											<img
												src={scopedFormData.img}
												alt={scopedFormData.index}
												style={{ height: 200 }}
											/>
										</div>
									)}
									<BooleanInput label="ativo" source={getSource('active')} />
									<NumberInput label="index" source={getSource('index')} />
									<TextInput
										label="Texto do passo"
										source={getSource('text')}
										fullWidth
									/>
									<TextInput
										label="Título do passo"
										source={getSource('title')}
										fullWidth
									/>
									<TextInput
										label="Informações do passo"
										source={getSource('info')}
										fullWidth
									/>
								</div>
							);
						}}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Create>
);
