import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  Pagination,
  Toolbar,
  SaveButton,
  DeleteButton,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { validateRequired } from '../utils/validation';
import AgencyField from '../components/AgencyField';

const OrientationPagination = props => (
	<Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />
);

const OrientationTitle = () => {
	return <span>Orientações</span>;
};

const OrientationEditToolbar = props => (
	<Toolbar {...props}>
		<DeleteButton />
		<SaveButton />
	</Toolbar>
);

export const OrientationList = props => (
	<List
		{...props}
		pagination={<OrientationPagination />}
		title="Orientações"
		bulkActionButtons={null}
	>
		<Datagrid rowClick="edit">

			<TextField label="Descrição" source="description" />

			<ReferenceArrayField label="Workplaces" source="workplace" reference="agency-admin">
				<SingleFieldList>
					<AgencyField />
				</SingleFieldList>
			</ReferenceArrayField>

			<ReferenceArrayField label="Empresa" source="company" reference="company-admin">
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ReferenceArrayField>

		</Datagrid>
	</List>
);

export const OrientationEdit = props => (
	<Edit title={<OrientationTitle />} {...props}>
		<SimpleForm toolbar={<OrientationEditToolbar />}>

		<TextInput validate={validateRequired} label="Descrição" source="description"/>

		<ReferenceArrayInput label="Empresas" source="company" reference="company-admin">
			<SelectArrayInput optionText="name" />
		</ReferenceArrayInput>

		<ReferenceArrayInput
			fullWidth
			label="Workplaces"
			source="workplace"
			reference="agency-admin"
			perPage={100}
		>
			<SelectArrayInput optionText={choice =>
				`[${choice.companyId.name} - ${choice.number}] ${choice.address}`}
			/>
		</ReferenceArrayInput>

		</SimpleForm>
	</Edit>
);

export const OrientationCreate = props => (
	<Create title={<OrientationTitle />} {...props}>
		<SimpleForm toolbar={<OrientationEditToolbar />}>

		<TextInput validate={validateRequired} label="Descrição" source="description"/>

		<ReferenceArrayInput label="Empresa" source="company" reference="company-admin">
			<SelectArrayInput optionText="name" />
		</ReferenceArrayInput>

		<ReferenceArrayInput
			fullWidth
			label="Workplaces"
			source="workplace"
			reference="agency-admin"
			perPage={100}
		>
			<SelectArrayInput optionText={choice =>
				`[${choice.companyId.name} - ${choice.number}] ${choice.address}`}
			/>
		</ReferenceArrayInput>

		</SimpleForm>
	</Create>
);
