import { AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(
      `${process.env.REACT_APP_API_ROOT}/user/login`,
      {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
    );
    return fetch(request).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error('Email ou senha incorretos');
      }
      return response.json().then(data => {
        localStorage.setItem('token', data.token);
      });
    });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }
  return Promise.resolve();
};
